<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('mine.huishou')" :show="true" ></Head>
      <div class="common-box info-box">
          <div class="info-main" >

                <van-cell-group  class="password-box">
                    <van-cell size="large"    :title="'USDT'"    @click="changeFlag(true)"   >
                        <template #extra >
                            <img class="re-img" v-if="flag" :src="require('@/assets/imgs/repay-on.png')" alt="">
                            <img class="re-img" v-else :src="require('@/assets/imgs/repay-off.png')" alt="">
                            
                        </template>
                    </van-cell>
                    <van-cell size="large"    :title="$t('wallet.th')"   @click="changeFlag(false)"  >
                        <template #extra >
                            <img class="re-img" v-if="!flag" :src="require('@/assets/imgs/repay-on.png')" alt="">
                            <img class="re-img" v-else :src="require('@/assets/imgs/repay-off.png')" alt="">
                        </template>
                    </van-cell>
                </van-cell-group>

                <van-cell-group  class="password-box">
                    <van-cell size="large"    :value="item.total_amount | number_format"  :title="$t('common.good_value')"     />
                    <van-cell size="large"   :value="(sell_winning_rate) + '%'"  :title="$t('add.repay_fee') "   />
                    <van-cell size="large"  :title="flag?$t('add.repay_kede'):'฿'"  :value="flag?(item.total_amount*(1-thisinfo.sell_winning_rate)):(thamount )"     />
                </van-cell-group>

                <van-button class="add-btn"  @click="confirmRepay">{{$t('login.confirm')}}</van-button>
          </div>
      </div>
  </div>
</template>


<script>
import {myinfo,randomString} from "@/common"
import {Notify} from "vant"
export default {
    mixins:[myinfo],
    data(){
        return {
            item:{},
            lang:localStorage.getItem("langData") || 'th',
            thisinfo:JSON.parse(localStorage.getItem("dashboard")),
            flag:true,  // 默认 usdt 
            thamount:0
        }
    },
    mounted(){
        const item = JSON.parse(this.$route.query.item)
        this.item = item;
        console.log(item)
        this.$ajax.winningOrderSellInfo({
            win_orderid:item.winning_orderid,
            receive_current_id:8011,
        })
        .then(res=>{
            if(res.code==200){
                this.thamount = res.data.receive_amount;
            }
        })
    },
    computed:{
        sell_winning_rate(){
            return (this.thisinfo.sell_winning_rate * 100).toFixed(2)
        }
    },
    methods:{
        changeFlag(flag){
            this.flag = flag;
        },
        confirmRepay(){
            this.$ajax.sellwinning({
            form_token:randomString(),
            win_orderid:this.item.winning_orderid,
            receive_current_id:this.flag?1011:8011   // 1001 usdt  8011 th 
        })
        .then(res=>{
            if(res.code==200){
                Notify({ type: 'success', message: res.msg });
                this.$router.go(-1)
            }
        })         
        }
    }
}
</script>

